<template>
  <div class="matting-container">
    <a-modal v-model:visible="visible" :title="null" @ok="handleOk" @cancel="handleClose" :footer="null" width="840px"
      centered :maskClosable="false" wrapClassName="matting-wrap-container">
      <template #closeIcon>
        <div class="close-icon">
          <img :src="close_icon" :style="{ width: '15.46px', height: '15.46px' }" />
        </div>
      </template>
      <div class="modal-wrap">
        <div class="modal-container">
          <div class="edit-canvas-container">
            <matting-board :rawImage="innerRawImage" :mattingImage="mattingImage" :isErasing="isErasing"
              :radius="brushSize" :hardness="hardness" :zoomRatio="zoomRatio" ref="mattingBoardBox"
              @canvasClick="canvasClick" />
          </div>
          <div class="operation-container">
            <div class="input-number-box">
              <div class="symbol-tip symbol-tip-add" @click="handleReduceSize">
                <img :src="iconReduce">
              </div>

              <div class="percent-input-box">
                {{ resize }}%
              </div>
              <div class="symbol-tip symbol-tip-reduce" @click="handleAddSize">
                <img :src="iconAdd">
              </div>
            </div>

            <div class="erase-restore-btns">
              <div class="erase-btn btn" :class="{ 'btn-selected': isErasing }" @click="handleEraseClick">
                Erase
              </div>
              <div class="restore-btn btn" :class="{ 'btn-selected': !isErasing }" @click="handleRestoreClick">
                Repair
              </div>
            </div>

            <div class="brush-size-box">
              <p>Brush Size</p>
              <div>
                <a-slider :value="brushSize" :max="100" :min="5" @change="handleAfterChange" />
              </div>
            </div>
          </div>
        </div>

        <div class="btns-box" :style="{ width: '454px' }">
          <div type="default" class="upload-btn" @click="handleReset">Reset</div>
          <!-- <a-button type="default" @click="handleDone">Done</a-button> -->
          <div type="default" class="upload-btn" @click="handleDone">Done</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, defineProps, watchEffect, defineEmits, computed } from 'vue';
import MattingBoard from '../matting-board';
import iconReduce from '../../assets/icon-reduce.svg'
import iconAdd from '../../assets/icon-add.svg'
import { postCanvasToURL } from '../../utils/buriedFunction/getWebpFromCanvas.js'
import close_icon from '../../assets/close_icon.svg'
const emits = defineEmits(['closeModal', 'savaMatting']);
const props = defineProps({
  isShowMattingModal: Boolean,
  rawImage: ImageData,
  resultImage: ImageData,
})

const innerRawImage = ref({})
const visible = ref(false);
const isErasing = ref(true);
const hardness = ref(0.5);
const brushSize = ref(12);
const resize = ref(100);
const mattingBoardBox = ref(null)
const zoomRatio = computed(() => resize.value / 100);

const mattingImage = ref(props.resultImage)
const originImage = ref(props.resultImage)

const operateParams = ref({
  erase: 0,
  repair: 0
})


watchEffect(() => {
  visible.value = props.isShowMattingModal
  if (props.resultImage) {
    mattingImage.value = props.resultImage
    originImage.value = props.resultImage
  }

  if (props.rawImage) {
    innerRawImage.value = props.rawImage
  }
});



const canvasClick = (data) => {
  if (data.isErasing) {
    operateParams.value.erase += 1
  } else {
    operateParams.value.repair += 1
  }

}

const handleClose = () => {
  mattingBoardBox.value?.resetMattingBoard();
  handleOk()
}
const handleOk = () => {
  clearCanvas()
  visible.value = false;
  resize.value = 100
  emits('closeModal', false)
};

const handleEraseClick = () => {
  isErasing.value = true;
}

const handleRestoreClick = () => {
  isErasing.value = false;
}

// 清除canvas画布
const clearCanvas = () => {
  const theCanvas = document.getElementById('matting-canvas')

  if (!theCanvas) {
    return
  }

  const context = theCanvas.getContext("2d");
  var w = theCanvas.width;
  var h = theCanvas.height;
  context.clearRect(0, 0, w, h);
}

const handleAfterChange = (value) => {
  brushSize.value = value
}

const handleReduceSize = () => {
  let resizeNum = resize.value
  if (resizeNum <= 20) {
    return
  }
  resizeNum -= 20
  resize.value = resizeNum;
}

const handleAddSize = () => {
  let resizeNum = resize.value
  if (resizeNum >= 400) {
    return
  }
  resizeNum += 20
  resize.value = resizeNum
}

const handleReset = () => {
  mattingBoardBox.value?.resetMattingBoard();
}



const handleDone = () => {
  const resultImage = mattingBoardBox.value.getMattingResult();
  console.log('[ operateParams.value ] >', operateParams.value)
  postCanvasToURL(document.getElementById("matting-board-canvas"), "toolkits_bgremove_edit", operateParams.value)
  emits('savaMattingImage', resultImage);
  //清空当前操作
  operateParams.value = {
    erase: 0,
    repair: 0
  }
  handleOk();
}
</script>

<style lang="less" scoped>
.matting-wrap-container {
  .ant-modal-body {
    padding: 30px 36px 24px 36px;
    // background-color: green;

  }

  :global(.ant-modal-close-x) {
    width: 18px;
    height: 18px;
    margin-right: 28.27px;
    margin-top: 22.27px
  }
}

.close-icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all .5s;

  &:hover {
    background-color: #eaeaea;
  }
}

.modal-container {
  height: 100%;
  width: 100%;
  display: flex;
}


.edit-canvas-container {
  width: 454px;
  height: 454px;
  background-color: #EDEDED;
  overflow: scroll;

  &::-webkit-scrollbar-track {
    background-color: none;
    border-radius: 3.5px;
  }

  /*定义滚动条高宽及背景*/
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /*定义滚动条*/
  &::-webkit-scrollbar-thumb {
    height: 7px;
    background: rgba(31, 35, 41, 0.7);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-corner {
    background-color: #EDEDED;
  }
}

.canvas-board-container {
  // position: absolute;
}

.btns-box {
  margin-top: 14px;
  display: flex;
  justify-content: center;

  .ant-btn {
    width: 130px;
    height: 42px;
    border: 1px solid #D0D0D0;
    border-radius: 200px;
    cursor: pointer;
  }

  .upload-btn {
    margin-right: 16px;
    cursor: pointer;
  }

  button[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    color: rgba(0, 0, 0, 0.85);
    animation: none 0 ease 0 1 normal;
  }

  .upload-btn:hover {
    color: rgba(0, 0, 0, 0.85);
    background-color: #EDEDED !important;
    outline: none;
  }

  .upload-btn:focus {
    outline: none
  }
}

.operation-container {
  height: 100%;
  width: 270px;
  min-width: 270px;
  padding-top: 152px;
  margin-left: 44px;

  p {
    margin-bottom: 10px;
  }

  img {
    border-radius: 4.14545px;
    margin-right: 12px;
    margin-bottom: 8.93px;
  }
}

.bg-title {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.input-number-box {
  display: flex;
  margin-bottom: 66px;
}

.symbol-tip {
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #D0D0D0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8.5px;
  cursor: pointer;

  img {
    margin: 0;
  }
}

.percent-input-box {
  border-top: 1px solid #D0D0D0;
  border-bottom: 1px solid #D0D0D0;
  width: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.percent-input {
  width: 66px;
  border: none;
}

.symbol-tip-add {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;

  &:hover {
    background-color: #EDEDED;
  }
}

.symbol-tip-reduce {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;

  &:hover {
    background-color: #EDEDED;
  }
}

.ant-input-number {
  width: 98px;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}

.erase-restore-btns {
  display: flex;

  :global(.btn) {
    width: 130px;
    height: 42px;
    background: #262626;
    border: 1px solid #262626;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
  }

  .erase-btn {
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #000000;
    width: 130px;
  }

  .restore-btn {
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #000000;
    width: 130px;
  }

  .btn-selected {
    background: #262626;
    color: #FFFFFF;
    border: 1px solid #262626;
  }
}

.brush-size-box {
  margin-top: 18px;
  font-size: 14px;

  p {
    margin-bottom: 14px;
  }

  .ant-slider {
    margin: 0;
  }


  :global(.ant-slider-rail) {
    background-color: #D9D9D9;
    border-radius: 20px;
  }


  :global(.ant-slider-track) {
    background-color: #5A5A5A !important;
    border-radius: 20px;

    &:hover {
      background-color: #5A5A5A;
    }
  }



  :global(.ant-slider-handle) {
    width: 18px;
    height: 18px;
    background-color: #575757;
    border: solid 2px #575757 !important;
    margin-top: -8px;
    box-shadow: none;

    transition: none;

    &:focus {
      border-color: #575757;
      background-color: #575757;
      box-shadow: none;
    }
  }

  :global(.ant-slider-handle.ant-slider-handle) {
    border-color: #575757;
    background-color: #fff;
    box-shadow: none;
  }


  :global(.ant-slider-handle.ant-tooltip-open) {
    background-color: #5A5A5A;
    border-color: #5A5A5A;

    &:focus {
      border-color: #5A5A5A;
      background-color: #fff;
      box-shadow: none;
    }
  }

  :global(.ant-slider-handle.ant-slider-handle-click-focused) {
    background-color: #fff;

    &:focus {
      border-color: #5A5A5A;
      background-color: #fff;
      box-shadow: none;
    }
  }

  // :global(.ant-slider-rail) {
  //   background-color: #DFDFDF;
  //   border-radius: 20px;
  // }


  // :global(.ant-slider-track) {
  //   background-color: #5A5A5A !important;
  //   border-radius: 20px;

  //   &:hover {
  //     background-color: #5A5A5A;
  //   }
  // }



  // :global(.ant-slider-handle) {
  //   width: 18px;
  //   height: 18px;
  //   background-color: #262626;
  //   border: solid 2px #262626 !important;
  //   margin-top: -8px;
  //   box-shadow: none;

  //   transition: none;

  //   &:focus {
  //     border-color: #262626;
  //     background-color: #262626;
  //     box-shadow: none;
  //   }
  // }

  // :global(.ant-slider-handle.ant-slider-handle) {
  //   border-color: #262626;
  //   background-color: #262626;
  //   box-shadow: none;
  // }



  // :global(.ant-slider-handle.ant-tooltip-open) {
  //   background-color: #262626;
  //   border-color: #262626;

  //   &:focus {
  //     border-color: #262626;
  //     background-color: #262626;
  //     box-shadow: none;
  //   }
  // }

  // :global(.ant-slider-handle.ant-slider-handle-click-focused) {
  //   background-color: #5A5A5A;

  //   &:focus {
  //     border-color: #262626;
  //     background-color: #262626;
  //     box-shadow: none;
  //   }
  // }
}
</style>