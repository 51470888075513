"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "matting-box-inner" };
var _hoisted_2 = ["width", "height"];
var _hoisted_3 = ["src"];
var reactivity_1 = require("@vue/reactivity");
var vue_3 = require("vue");
var use_matting_board_1 = require("./composables/use-matting-board");
var use_matting_cursor_1 = require("./composables/use-matting-cursor");
var dom_helper_1 = require("./helpers/dom-helper");
exports.default = vue_1.defineComponent({
    __name: 'index',
    props: {
        rawImage: ImageData,
        mattingImage: ImageData,
        isErasing: Boolean,
        radius: Number,
        hardness: Number,
        zoomRatio: {
            type: Number,
            required: true,
            default: 1
        },
    },
    emits: ['closeModal', 'savaMatting'],
    setup: function (__props, _a) {
        var expose = _a.expose, emits = _a.emit;
        var props = __props;
        expose({
            resetMattingBoard: resetMattingBoard,
            getMattingResult: getMattingResult,
        });
        var mattingBoxRef = vue_3.ref(null);
        var mattingCvsRef = vue_3.ref(null);
        var sessionId = vue_3.ref(Math.random());
        var rawImage = vue_3.ref(props.rawImage);
        var mattingImage = vue_3.ref(props.mattingImage);
        var isErasing = vue_3.ref(props.isErasing);
        var radius = vue_3.ref(props.radius);
        var hardness = vue_3.ref(props.hardness);
        var zoomRatio = vue_3.ref(props.zoomRatio);
        var canvasCtxSize = reactivity_1.computed(function () {
            return {
                width: width.value * 2,
                height: height.value * 2
            };
        });
        vue_3.watchEffect(function () {
            rawImage.value = props.rawImage;
            mattingImage.value = props.mattingImage;
            isErasing.value = props.isErasing;
            radius.value = props.radius;
            hardness.value = props.hardness;
            zoomRatio.value = props.zoomRatio;
        });
        var _b = use_matting_board_1.useMattingBoard({ sessionId: sessionId, rawImage: rawImage, mattingImage: mattingImage, isErasing: isErasing, radius: radius, hardness: hardness, zoomRatio: zoomRatio }), width = _b.width, height = _b.height, inputCtx = _b.inputCtx, imageSources = _b.imageSources, inputHiddenCtx = _b.inputHiddenCtx, draggingInputBoard = _b.draggingInputBoard;
        var _c = use_matting_cursor_1.default({ inputCtx: inputCtx, isDragging: draggingInputBoard, isErasing: isErasing, radius: radius, hardness: hardness, zoomRatio: zoomRatio }), cursorImage = _c.cursorImage, mattingCursorStyle = _c.mattingCursorStyle, renderOutputCursor = _c.renderOutputCursor;
        vue_3.onMounted(function () {
            var canvas = document.getElementById("matting-board-canvas");
            console.log('[ canvas ] >', canvas);
            canvas.addEventListener("click", canvasClick, false);
            initContextsAndSize();
            renderOutputCursor();
        });
        function canvasClick() {
            var params = {
                isErasing: isErasing.value
            };
            emits('canvasClick', params);
        }
        function initContextsAndSize() {
            var mattingCanvas = mattingCvsRef.value;
            inputCtx.value = mattingCanvas.getContext('2d');
            var mattingBox = mattingBoxRef.value;
            var boxWidth = mattingBox.clientWidth;
            var boxHeight = mattingBox.clientHeight;
            vue_3.watchEffect(function () {
                if (mattingImage.value) {
                    var validImageSize = use_matting_board_1.computeValidImageSize(mattingImage.value, { width: boxWidth, height: boxHeight }, { horizontal: 0, vertical: 0 });
                    width.value = validImageSize.width * 2;
                    height.value = validImageSize.height * 2;
                }
            });
        }
        function resetMattingBoard() {
            sessionId.value = Math.random();
        }
        function getMattingResult() {
            return dom_helper_1.generateResultImage(imageSources.value.raw, inputHiddenCtx.value);
        }
        return function (_ctx, _cache) {
            return (vue_2.openBlock(), vue_2.createElementBlock("div", {
                class: "matting-box",
                ref_key: "mattingBoxRef",
                ref: mattingBoxRef
            }, [
                vue_2.createElementVNode("div", _hoisted_1, [
                    vue_2.createElementVNode("canvas", {
                        class: "matting-board",
                        ref_key: "mattingCvsRef",
                        ref: mattingCvsRef,
                        width: vue_2.unref(width),
                        height: vue_2.unref(height),
                        id: "matting-board-canvas",
                        style: vue_2.normalizeStyle({
                            width: (vue_2.unref(width) / 2 * zoomRatio.value) + 'px',
                            height: (vue_2.unref(height) / 2 * zoomRatio.value) + 'px'
                        })
                    }, null, 12, _hoisted_2),
                    vue_2.createElementVNode("img", {
                        class: "matting-cursor",
                        style: vue_2.normalizeStyle(vue_2.unref(mattingCursorStyle)),
                        src: vue_2.unref(cursorImage)
                    }, null, 12, _hoisted_3)
                ])
            ], 512));
        };
    }
});
