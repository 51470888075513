"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initScaleListener = exports.initDragListener = exports.computeScaleRatio = exports.computeValidImageSize = exports.useInitTransformListener = exports.useMattingBoard = void 0;
var vue_1 = require("vue");
var lodash_1 = require("lodash");
var constants_1 = require("../helpers/constants");
var util_1 = require("../helpers/util");
var listener_manager_1 = require("../helpers/listener-manager");
var init_drawing_listener_1 = require("../helpers/init-drawing-listener");
var dom_helper_1 = require("../helpers/dom-helper");
var transform_helper_1 = require("../helpers/transform-helper");
function useMattingBoard(props) {
    var width = vue_1.ref(0);
    var height = vue_1.ref(0);
    var inputCtx = vue_1.ref(null);
    var inputHiddenCtx = vue_1.ref(dom_helper_1.createContext2D());
    var inputDrawingCtx = dom_helper_1.createContext2D();
    var isDrawing = vue_1.ref(false);
    var draggingInputBoard = vue_1.ref(false);
    var transformConfig = vue_1.reactive(constants_1.INITIAL_TRANSFORM_CONFIG);
    var imageSources = vue_1.ref(null);
    var boardRect = vue_1.ref(null);
    var initialized = vue_1.ref(false);
    var initMattingResult = vue_1.ref(null);
    var listenerManager = new listener_manager_1.default();
    var initMattingConfig = {
        boardContexts: { inputCtx: inputCtx, inputDrawingCtx: inputDrawingCtx, inputHiddenCtx: inputHiddenCtx },
        initMattingResult: initMattingResult,
        transformConfig: transformConfig,
        imageSources: imageSources,
        initialized: initialized,
        boardRect: boardRect,
    };
    var initListenersConfig = __assign(__assign({}, initMattingConfig), { draggingInputBoard: draggingInputBoard, isDrawing: isDrawing, listenerManager: listenerManager });
    useInitMattingBoards(props, __assign(__assign({}, initMattingConfig), { width: width, height: height }));
    useInitDrawingListeners(props, initListenersConfig);
    return {
        width: width,
        height: height,
        inputCtx: inputCtx,
        inputHiddenCtx: inputHiddenCtx,
        draggingInputBoard: draggingInputBoard,
        transformConfig: transformConfig,
        imageSources: imageSources,
    };
}
exports.useMattingBoard = useMattingBoard;
function useInitMattingBoards(props, useInitMattingBoardsConfig) {
    var _this = this;
    var sessionId = props.sessionId, rawImage = props.rawImage, mattingImage = props.mattingImage;
    var width = useInitMattingBoardsConfig.width, height = useInitMattingBoardsConfig.height, boardRect = useInitMattingBoardsConfig.boardRect, initialized = useInitMattingBoardsConfig.initialized, imageSources = useInitMattingBoardsConfig.imageSources, transformConfig = useInitMattingBoardsConfig.transformConfig, initMattingResult = useInitMattingBoardsConfig.initMattingResult, boardContexts = useInitMattingBoardsConfig.boardContexts, _a = useInitMattingBoardsConfig.boardContexts, inputCtx = _a.inputCtx, inputHiddenCtx = _a.inputHiddenCtx;
    var updateBoardRect = function () {
        boardRect.value = computeBoardRect(inputCtx.value.canvas);
    };
    var resizeBoards = function () {
        requestAnimationFrame(function () {
            var commonConfig = { targetHeight: height.value, targetWidth: width.value, transformConfig: transformConfig };
            dom_helper_1.resizeCanvas(__assign({ ctx: inputCtx.value, hiddenCtx: inputHiddenCtx.value }, commonConfig));
        });
    };
    vue_1.watchEffect(function () { return __awaiter(_this, void 0, void 0, function () {
        var _a, _b, rawSource, mattingSource, positionRange, scaleRatio;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!(sessionId.value && rawImage.value && mattingImage.value && width.value && height.value)) return [3, 2];
                    initialized.value = false;
                    _a = initMattingResult;
                    return [4, initMatting({
                            boardContexts: boardContexts,
                            rawImage: rawImage.value,
                            mattingImage: mattingImage.value,
                            targetSize: { width: width.value, height: height.value },
                            gapSize: { horizontal: 0, vertical: 0 },
                            transformConfig: {},
                        })];
                case 1:
                    _a.value = _c.sent();
                    _b = initMattingResult.value, rawSource = _b.rawSource, mattingSource = _b.mattingSource, positionRange = _b.positionRange, scaleRatio = _b.scaleRatio;
                    transformConfig.positionRange = positionRange;
                    transformConfig.scaleRatio = scaleRatio;
                    imageSources.value = { raw: rawSource, matting: mattingSource };
                    updateBoardRect();
                    resizeBoards();
                    initialized.value = true;
                    _c.label = 2;
                case 2: return [2];
            }
        });
    }); });
    vue_1.onMounted(function () {
        window.addEventListener(constants_1.EventType.Resize, resizeBoards);
        window.addEventListener(constants_1.EventType.Scroll, lodash_1.debounce(updateBoardRect, constants_1.UPDATE_BOARDRECT_DEBOUNCE_TIME));
    });
    vue_1.onUnmounted(function () {
        window.removeEventListener(constants_1.EventType.Resize, resizeBoards);
    });
}
function useInitDrawingListeners(props, config) {
    var radius = props.radius, hardness = props.hardness, isErasing = props.isErasing, zoomRatio = props.zoomRatio;
    var boardContexts = config.boardContexts, transformConfig = config.transformConfig, imageSources = config.imageSources, draggingInputBoard = config.draggingInputBoard, initialized = config.initialized, boardRect = config.boardRect, listenerManager = config.listenerManager;
    var inputCtx = boardContexts.inputCtx;
    vue_1.watchEffect(function () {
        if (initialized.value) {
            init_drawing_listener_1.initDrawingListeners({
                listenerManager: listenerManager,
                imageSources: imageSources.value,
                boardContexts: boardContexts,
                initDrawingConfig: { radius: radius, hardness: hardness, zoomRatio: zoomRatio, transformConfig: transformConfig },
                isErasing: isErasing.value,
                draggingInputBoard: draggingInputBoard.value,
                boardRect: boardRect.value,
            });
        }
    });
    vue_1.onBeforeUnmount(function () {
        listenerManager.removeMouseListeners(inputCtx.value.canvas);
    });
}
function useInitTransformListener(config, props) {
    var _this = this;
    var boardContexts = config.boardContexts, initialized = config.initialized, draggingInputBoard = config.draggingInputBoard, transformConfig = config.transformConfig, isDrawing = config.isDrawing, listenerManager = config.listenerManager;
    var inputCtx = boardContexts.inputCtx, inputHiddenCtx = boardContexts.inputHiddenCtx;
    vue_1.watch([initialized, draggingInputBoard, isDrawing], function () {
        if (initialized.value && !isDrawing.value) {
            var initConfig = {
                inputContexts: { ctx: inputCtx.value, hiddenCtx: inputHiddenCtx.value },
                draggingInputBoard: draggingInputBoard.value,
                listenerManager: listenerManager,
                transformConfig: transformConfig
            };
            initDragListener(initConfig);
            initScaleListener(initConfig);
            if (!draggingInputBoard.value) {
                transformConfig.positionRange = __assign({}, transformConfig.positionRange);
            }
        }
    }, { deep: true });
    vue_1.watch([transformConfig], function () { return __awaiter(_this, void 0, void 0, function () {
        var positionRange, scaleRatio, commonConfig;
        return __generator(this, function (_a) {
            if (initialized.value) {
                positionRange = transformConfig.positionRange, scaleRatio = transformConfig.scaleRatio;
                commonConfig = { positionRange: positionRange, scaleRatio: scaleRatio };
                dom_helper_1.transformedDrawImage(__assign({ ctx: inputCtx.value, hiddenCtx: inputHiddenCtx.value }, commonConfig));
            }
            return [2];
        });
    }); });
    vue_1.onBeforeUnmount(function () {
        if (initialized.value) {
            listenerManager.removeMouseListeners(inputCtx.value.canvas);
            listenerManager.removeWheelListeners();
        }
    });
}
exports.useInitTransformListener = useInitTransformListener;
function initMatting(initMattingConfig) {
    return __awaiter(this, void 0, Promise, function () {
        var _a, inputCtx, inputHiddenCtx, inputDrawingCtx, rawImage, mattingImage, transformConfig, targetSize, gapSize, rawSource, mattingSource, _b, scaleRatio, positionRange, validImageSize;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _a = initMattingConfig.boardContexts, inputCtx = _a.inputCtx, inputHiddenCtx = _a.inputHiddenCtx, inputDrawingCtx = _a.inputDrawingCtx, rawImage = initMattingConfig.rawImage, mattingImage = initMattingConfig.mattingImage, transformConfig = initMattingConfig.transformConfig, targetSize = initMattingConfig.targetSize, gapSize = initMattingConfig.gapSize;
                    inputCtx.value.imageSmoothingEnabled = constants_1.DEFAULT_IMAGE_SMOOTH_CHOICE;
                    return [4, createImageBitmap(rawImage)];
                case 1:
                    rawSource = _c.sent();
                    return [4, createImageBitmap(mattingImage)];
                case 2:
                    mattingSource = _c.sent();
                    _b = getValidTransformConfig({ imageSource: mattingSource, transformConfig: transformConfig, targetSize: targetSize, gapSize: gapSize }), scaleRatio = _b.scaleRatio, positionRange = _b.positionRange;
                    validImageSize = computeValidImageSize(mattingSource, constants_1.HIDDEN_BOARD_MAX_SIZE, constants_1.HIDDEN_BOARD_GAP_SIZE);
                    initHiddenBoardWithSource({
                        imageSource: mattingSource,
                        targetSize: validImageSize,
                        hiddenCtx: inputHiddenCtx.value,
                        drawingCtx: inputDrawingCtx,
                    });
                    dom_helper_1.transformedDrawImage({
                        hiddenCtx: inputHiddenCtx.value,
                        ctx: inputCtx.value,
                        scaleRatio: scaleRatio,
                        positionRange: positionRange,
                    });
                    return [2, { rawSource: rawSource, mattingSource: mattingSource, positionRange: positionRange, scaleRatio: scaleRatio }];
            }
        });
    });
}
function initHiddenBoardWithSource(initConfig) {
    initHiddenBoard(initConfig);
    var ctx = initConfig.hiddenCtx, imageSource = initConfig.imageSource, _a = initConfig.targetSize, width = _a.width, height = _a.height;
    return getImageSourceFromCtx({ ctx: ctx, imageSource: imageSource, width: width, height: height });
}
function initHiddenBoard(initConfig) {
    var targetSize = initConfig.targetSize, hiddenCtx = initConfig.hiddenCtx, drawingCtx = initConfig.drawingCtx;
    var width = targetSize.width, height = targetSize.height;
    hiddenCtx.canvas.width = width;
    hiddenCtx.canvas.height = height;
    drawingCtx.canvas.width = width;
    drawingCtx.canvas.height = height;
}
function getValidTransformConfig(getParametersConfig) {
    var transformConfig = getParametersConfig.transformConfig, computeConfig = __rest(getParametersConfig, ["transformConfig"]);
    if (isInvalidTransformConfig(transformConfig)) {
        var _a = computeTransformConfig(computeConfig), scaleRatio = _a.scaleRatio, positionRange = _a.positionRange;
        transformConfig.scaleRatio = scaleRatio;
        transformConfig.positionRange = positionRange;
    }
    return transformConfig;
}
function isInvalidTransformConfig(transformConfig) {
    var scaleRatio = transformConfig.scaleRatio, positionRange = transformConfig.positionRange;
    return !scaleRatio || !positionRange;
}
function computeTransformConfig(computeConfig) {
    var imageSource = computeConfig.imageSource, targetSize = computeConfig.targetSize, _a = computeConfig.gapSize, gapSize = _a === void 0 ? constants_1.INITIAL_GAP_SIZE : _a;
    var imageSize = computeValidImageSize(imageSource, constants_1.HIDDEN_BOARD_MAX_SIZE, constants_1.HIDDEN_BOARD_GAP_SIZE);
    return computeTransformParameters({
        gapSize: gapSize,
        imageSize: imageSize,
        targetSize: targetSize,
    });
}
function computeValidImageSize(imageSource, targetSize, gapSize) {
    var width = imageSource.width, height = imageSource.height;
    var imageScaleRatio = computeScaleRatio({
        imageSize: { width: width, height: height },
        gapSize: gapSize,
        targetSize: targetSize,
    });
    console.log('imageScaleRatio', imageScaleRatio);
    width *= imageScaleRatio;
    height *= imageScaleRatio;
    return { width: width, height: height, imageScaleRatio: imageScaleRatio };
}
exports.computeValidImageSize = computeValidImageSize;
function computeScaleRatio(transformParametersConfig) {
    var imageSize = transformParametersConfig.imageSize, gapSize = transformParametersConfig.gapSize, targetSize = transformParametersConfig.targetSize;
    var drawingAreaSize = getDrawingAreaSize(targetSize, gapSize);
    return Math.min(drawingAreaSize.width / imageSize.width, drawingAreaSize.height / imageSize.height);
}
exports.computeScaleRatio = computeScaleRatio;
function computeTransformParameters(transformParametersConfig) {
    var scaleRatio = computeScaleRatio(transformParametersConfig);
    var positionRange = computePositionRange(transformParametersConfig, scaleRatio);
    return { scaleRatio: scaleRatio, positionRange: positionRange };
}
function computePositionRange(transformParametersConfig, scaleRatio) {
    var scaledImageSize = computeScaledImageSize(transformParametersConfig.imageSize, scaleRatio);
    return {
        minX: getPositionRangeMinX(transformParametersConfig, scaledImageSize),
        maxX: getPositionRangeMaxX(transformParametersConfig, scaledImageSize),
        minY: getPositionRangeMinY(transformParametersConfig, scaledImageSize),
        maxY: getPositionRangeMaxY(transformParametersConfig, scaledImageSize),
    };
}
function computeScaledImageSize(imageSize, scaleRatio) {
    return {
        width: imageSize.width * scaleRatio,
        height: imageSize.height * scaleRatio,
    };
}
function computeBoardRect(canvas) {
    var inputBoardRect = canvas.getBoundingClientRect();
    var domRect = document.documentElement.getBoundingClientRect();
    return computeBoardRectSize(inputBoardRect, domRect);
}
function computeBoardRectSize(inputBoardRect, domRect) {
    var width = inputBoardRect.width, height = inputBoardRect.height, boardLeft = inputBoardRect.left, boardTop = inputBoardRect.top;
    var domLeft = domRect.left, domTop = domRect.top;
    var left = boardLeft - domLeft;
    var top = boardTop - domTop;
    return { left: left, top: top, width: width, height: height };
}
function getPositionRangeMinX(transformParametersConfig, scaledImageSize) {
    var gapSize = transformParametersConfig.gapSize, targetSize = transformParametersConfig.targetSize;
    return util_1.fixed((getDrawingAreaSize(targetSize, gapSize).width - scaledImageSize.width) / 2) + gapSize.horizontal;
}
function getPositionRangeMinY(transformParametersConfig, scaledImageSize) {
    var gapSize = transformParametersConfig.gapSize, targetSize = transformParametersConfig.targetSize;
    return util_1.fixed((getDrawingAreaSize(targetSize, gapSize).height - scaledImageSize.height) / 2) + gapSize.vertical;
}
function getPositionRangeMaxX(transformParametersConfig, scaledImageSize) {
    return util_1.fixed(getPositionRangeMinX(transformParametersConfig, scaledImageSize) + scaledImageSize.width);
}
function getPositionRangeMaxY(transformParametersConfig, scaledImageSize) {
    return util_1.fixed(getPositionRangeMinY(transformParametersConfig, scaledImageSize) + scaledImageSize.height);
}
function getDrawingAreaSize(boardSize, gapSize) {
    return {
        width: boardSize.width - gapSize.horizontal * 2,
        height: boardSize.height - gapSize.vertical * 2,
    };
}
function getImageSourceFromCtx(config) {
    var ctx = config.ctx, imageSource = config.imageSource, width = config.width, height = config.height;
    ctx.drawImage(imageSource, 0, 0, width, height);
    return createImageBitmap(ctx.canvas);
}
function initDragListener(mattingTransformConfig) {
    var inputCtx2D = mattingTransformConfig.inputContexts.ctx, transformConfig = mattingTransformConfig.transformConfig, listenerManager = mattingTransformConfig.listenerManager;
    listenerManager.initMouseListeners({
        mouseTarget: inputCtx2D.canvas,
        move: function (ev) {
            var positionRange = transformConfig.positionRange;
            transform_helper_1.updateRangeByMovements(ev, positionRange);
        },
    });
}
exports.initDragListener = initDragListener;
function initScaleListener(mattingTransformConfig) {
    var inputCtx = mattingTransformConfig.inputContexts.ctx, listenerManager = mattingTransformConfig.listenerManager;
    return listenerManager.initWheelListener({
        mattingBoards: [inputCtx.canvas],
        wheel: function (ev) {
            transform_helper_1.redrawMattingBoardsWhileScaling(ev, mattingTransformConfig);
        },
    });
}
exports.initScaleListener = initScaleListener;
