//image_id  str
//emoji_id  int
//canvas_image  base64
//background_image_id
import { buryPointReport } from "../../../api/image-filter";
import axios from "axios";
var md5 = require("md5");
import { getSessionsSource, getDeviceType } from "../../index.js";

export function addDesignEmoji (
  eventName,
  emojiId,
  backgroundImageId,
  currentImageId
) {
  const timestamp = Date.now();
  const deviceId = localStorage.getItem("browserId");
  const sessionId = sessionStorage.getItem("sessionId");
  const signId = md5(1 + deviceId + timestamp + eventName + "gJUyT9qGK");
  //图片id
  const imageId = sessionStorage.getItem("sourceImageId");
  let emojiString = "star_struck";
  switch (emojiId) {
    case 1:
      emojiString = "star_struck";
      break;
    case 2:
      emojiString = "face_blowing_a_kiss";
      break;
    case 3:
      emojiString = "thinking_face";
      break;
    case 4:
      emojiString = "face_vomiting";
      break;
  }
  const params = {
    app_id: 1,
    app_name: "ai_tools",
    user_id: "",
    device_id: deviceId, //当前设备浏览器唯一id
    session_id: sessionId, //当前浏览器页面的sessionid
    timestamp: timestamp, // 当前时间戳，13 位毫秒级
    device_type: getDeviceType(),
    session_source: getSessionsSource(),
    event: {
      event_name: eventName,
      properties: {
        source_image_id: imageId,
        current_image_id: currentImageId,
        emoji_str: emojiString,
        background_image_id: backgroundImageId,
      },
    },
    sign: signId,
  };
  buryPointReport({ ...params });
}
