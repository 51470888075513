"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MattingCursor = void 0;
var vue_1 = require("vue");
var constants_1 = require("../helpers/constants");
var dom_helper_1 = require("../helpers/dom-helper");
var MattingCursor = (function () {
    function MattingCursor(inputCtx, isErasing) {
        var _this = this;
        this.inputCtx = inputCtx;
        this.isErasing = isErasing;
        this.cursorImage = vue_1.ref('');
        this.inputCursorStyle = vue_1.ref(null);
        this.mattingCursorStyle = vue_1.reactive(Object.create(null));
        this.radius = vue_1.ref(constants_1.INITIAL_RADIUS);
        this.hardness = vue_1.ref(constants_1.INITIAL_HARDNESS);
        this.inputCanvas = vue_1.computed(function () { return _this.inputCtx.value.canvas; });
        this.pointInnerColor = vue_1.computed(function () { return (_this.isErasing.value ? constants_1.ERASE_POINT_INNER_COLOR : constants_1.REPAIR_POINT_INNER_COLOR); });
        this.pointOuterColor = vue_1.computed(function () { return (_this.isErasing.value ? constants_1.ERASE_POINT_OUTER_COLOR : constants_1.REPAIR_POINT_OUTER_COLOR); });
        this.ctx = this.creatCursorCanvas();
        this;
    }
    MattingCursor.prototype.creatCursorCanvas = function () {
        var ctx = document.createElement('canvas').getContext('2d');
        return this.updateCtx(ctx);
    };
    MattingCursor.prototype.updateCtx = function (ctx) {
        ctx.canvas.width = this.radius.value * 2.25;
        ctx.canvas.height = this.radius.value * 2.25;
        return ctx;
    };
    MattingCursor.prototype.createCursorImage = function () {
        return __awaiter(this, void 0, void 0, function () {
            var drawingConfig;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ctx = this.updateCtx(this.ctx);
                        drawingConfig = {
                            ctx: this.ctx,
                            x: this.radius.value,
                            y: this.radius.value,
                            radius: this.radius.value,
                            hardness: this.hardness.value,
                            innerColor: this.pointInnerColor.value,
                            outerColor: this.pointOuterColor.value,
                        };
                        dom_helper_1.drawBrushPoint(drawingConfig);
                        return [4, this.drawIcon()];
                    case 1:
                        _a.sent();
                        return [4, this.ctx.canvas.toDataURL()];
                    case 2: return [2, _a.sent()];
                }
            });
        });
    };
    MattingCursor.prototype.drawIcon = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2];
            });
        });
    };
    MattingCursor.prototype.renderOutputCursor = function () {
        var target = this.inputCanvas.value;
        target.addEventListener(constants_1.EventType.Mouseover, this.onShowCursor.bind(this));
        target.addEventListener(constants_1.EventType.Mousemove, this.onRenderOutputCursor.bind(this));
        target.addEventListener(constants_1.EventType.Mouseout, this.onHideCursor.bind(this));
    };
    MattingCursor.prototype.onShowCursor = function () {
        this.mattingCursorStyle.display = 'initial';
    };
    MattingCursor.prototype.onHideCursor = function () {
        this.mattingCursorStyle.display = 'none';
    };
    MattingCursor.prototype.onRenderOutputCursor = function (e) {
        this.mattingCursorStyle.left = e.offsetX - this.radius.value + 'px';
        this.mattingCursorStyle.top = e.offsetY - this.radius.value + 'px';
    };
    MattingCursor.prototype.changeOutputCursorByDrag = function (_a) {
        var isDragging = _a[0];
        if (isDragging) {
            this.onHideCursor();
        }
        else {
            this.onShowCursor();
        }
    };
    MattingCursor.prototype.updateCursorParams = function (currHardness, currRadius) {
        this.hardness.value = currHardness;
        this.radius.value = currRadius;
    };
    return MattingCursor;
}());
exports.MattingCursor = MattingCursor;
function useMattingCursor(config) {
    var _this = this;
    var inputCtx = config.inputCtx, isDragging = config.isDragging, isErasing = config.isErasing, hardness = config.hardness, radius = config.radius, zoomRatio = config.zoomRatio;
    var mattingCursor = new MattingCursor(inputCtx, isErasing);
    var cursorImage = mattingCursor.cursorImage, mattingCursorStyle = mattingCursor.mattingCursorStyle, renderOutputCursor = mattingCursor.renderOutputCursor;
    vue_1.watchEffect(function () { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    mattingCursor.updateCursorParams(hardness.value, radius.value * zoomRatio.value);
                    _a = cursorImage;
                    return [4, mattingCursor.createCursorImage()];
                case 1:
                    _a.value = _b.sent();
                    return [2];
            }
        });
    }); });
    vue_1.watch([isDragging], mattingCursor.changeOutputCursorByDrag.bind(mattingCursor));
    return {
        mattingCursor: mattingCursor,
        mattingCursorStyle: mattingCursorStyle,
        cursorImage: cursorImage,
        renderOutputCursor: renderOutputCursor.bind(mattingCursor),
    };
}
exports.default = useMattingCursor;
