"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("./constants");
var MouseDown = constants_1.EventType.MouseDown, Mouseup = constants_1.EventType.Mouseup, Mousemove = constants_1.EventType.Mousemove;
var ListenerManager = (function () {
    function ListenerManager() {
        this.unbindDownUpCache = new WeakMap();
        this.unbindMoveCache = new WeakMap();
        this.unbindWheelCache = new Set();
    }
    ListenerManager.prototype.initMouseListeners = function (ctx) {
        var mouseTarget = ctx.mouseTarget;
        this.removeMouseListeners(mouseTarget);
        var unbindConfig = this.bindMouseListeners(ctx);
        this.unbindDownUpCache.set(mouseTarget, unbindConfig);
    };
    ListenerManager.prototype.removeMouseListeners = function (mouseTarget) {
        var unbindConfig = this.unbindDownUpCache.get(mouseTarget);
        if (unbindConfig) {
            var unbindDown = unbindConfig.unbindDown, unbindUp = unbindConfig.unbindUp;
            unbindDown();
            unbindUp();
        }
    };
    ListenerManager.prototype.bindMouseListeners = function (listenersContext) {
        var _this = this;
        var mouseTarget = listenersContext.mouseTarget, down = listenersContext.down, move = listenersContext.move, up = listenersContext.up;
        var moveListener = function (ev) {
            requestAnimationFrame(function () { return move(ev); });
        };
        var downListener = function (ev) {
            var isTarget = ev.target === mouseTarget;
            var extraCondition = down && down(ev);
            var shouldBindMove = extraCondition !== false;
            if (isTarget && shouldBindMove) {
                var removeMove = _this.listenEvent({
                    eventType: Mousemove,
                    listener: moveListener,
                }, true, mouseTarget);
                _this.unbindMoveCache.set(mouseTarget, removeMove);
            }
        };
        var upListener = function (ev) {
            up && up(ev);
            _this.unbindMoveListeners(mouseTarget);
        };
        var unbindDown = this.listenEvent({
            eventType: MouseDown,
            listener: downListener,
        }, true, mouseTarget);
        var unbindUp = this.listenEvent({
            eventType: Mouseup,
            listener: upListener,
        });
        return { unbindDown: unbindDown, unbindUp: unbindUp };
    };
    ListenerManager.prototype.unbindMoveListeners = function (mouseTarget) {
        var unbindMove = this.unbindMoveCache.get(mouseTarget);
        unbindMove && unbindMove();
        this.unbindMoveCache.delete(mouseTarget);
    };
    ListenerManager.prototype.initWheelListener = function (listenersConfig) {
        this.removeWheelListeners();
        var removeWheel = this.bindWheelListener(listenersConfig);
        this.unbindWheelCache.add(removeWheel);
        return removeWheel;
    };
    ListenerManager.prototype.removeWheelListeners = function () {
        this.unbindWheelCache.forEach(function (unbind) { return unbind(); });
        this.unbindWheelCache.clear();
    };
    ListenerManager.prototype.bindWheelListener = function (listenersConfig) {
        var _this = this;
        var mattingBoards = listenersConfig.mattingBoards, wheel = listenersConfig.wheel;
        return this.listenEvent.apply(this, __spreadArrays([{
                eventType: constants_1.EventType.Wheel,
                listener: function (ev) {
                    if (_this.canWheel(ev, mattingBoards)) {
                        wheel(ev);
                    }
                },
            }, false], mattingBoards));
    };
    ListenerManager.prototype.canWheel = function (ev, mattingBoards) {
        return mattingBoards.some(function (board) { return ev.target === board; });
    };
    ListenerManager.prototype.listenEvent = function (listenerConfig, options) {
        if (options === void 0) { options = false; }
        var targets = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            targets[_i - 2] = arguments[_i];
        }
        var eventType = listenerConfig.eventType;
        var wrapListener = this.genWrapListener(listenerConfig);
        var removeListenerCallback;
        if (!this.isNeedToBindToTargets(targets)) {
            removeListenerCallback = this.bindListener(window, eventType, wrapListener, options);
        }
        else {
            removeListenerCallback = this.bindListeners(targets, eventType, wrapListener, options);
        }
        return removeListenerCallback;
    };
    ListenerManager.prototype.genWrapListener = function (listenerConfig) {
        var listener = listenerConfig.listener, stop = listenerConfig.stop, prevent = listenerConfig.prevent;
        return function (ev) {
            if (stop) {
                ev.stopPropagation();
            }
            if (prevent) {
                ev.preventDefault();
            }
            listener(ev);
        };
    };
    ListenerManager.prototype.isNeedToBindToTargets = function (targets) {
        return targets.length !== 0;
    };
    ListenerManager.prototype.bindListener = function (target, eventType, listener, options) {
        target.addEventListener(eventType, listener, options);
        return function () { return target.removeEventListener(eventType, listener, options); };
    };
    ListenerManager.prototype.bindListeners = function (targets, eventType, listener, options) {
        targets.forEach(function (target) {
            target.addEventListener(eventType, listener, options);
        });
        return function () {
            return targets.forEach(function (target) {
                target.removeEventListener(eventType, listener, options);
            });
        };
    };
    return ListenerManager;
}());
exports.default = ListenerManager;
