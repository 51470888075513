"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeHelpers = exports.computeTransformParameters = exports.getDrawingAreaSize = exports.computeScaleRatio = exports.computeValidImageSize = exports.computeBoardRectSize = exports.computeBoardRect = void 0;
var constants_1 = require("./constants");
var util_1 = require("./util");
function computeBoardRect(canvas) {
    var inputBoardRect = canvas.getBoundingClientRect();
    var domRect = document.documentElement.getBoundingClientRect();
    return computeBoardRectSize(inputBoardRect, domRect);
}
exports.computeBoardRect = computeBoardRect;
function computeBoardRectSize(inputBoardRect, domRect) {
    var width = inputBoardRect.width, height = inputBoardRect.height, boardLeft = inputBoardRect.left, boardTop = inputBoardRect.top;
    var domLeft = domRect.left, domTop = domRect.top;
    var left = boardLeft - domLeft;
    var top = boardTop - domTop;
    return { left: left, top: top, width: width, height: height };
}
exports.computeBoardRectSize = computeBoardRectSize;
function computeValidImageSize(imageSource) {
    var width = imageSource.width, height = imageSource.height;
    var imageScaleRatio = computeScaleRatio({
        imageSize: { width: width, height: height },
        gapSize: constants_1.HIDDEN_BOARD_GAP_SIZE,
        targetSize: constants_1.HIDDEN_BOARD_MAX_SIZE,
    });
    width *= imageScaleRatio;
    height *= imageScaleRatio;
    return { width: width, height: height };
}
exports.computeValidImageSize = computeValidImageSize;
function computeScaleRatio(transformParametersConfig) {
    var imageSize = transformParametersConfig.imageSize, gapSize = transformParametersConfig.gapSize, targetSize = transformParametersConfig.targetSize;
    var drawingAreaSize = getDrawingAreaSize(targetSize, gapSize);
    return Math.min(Math.min(drawingAreaSize.width / imageSize.width, drawingAreaSize.height / imageSize.height), constants_1.INITIAL_SCALE_RATIO);
}
exports.computeScaleRatio = computeScaleRatio;
function getDrawingAreaSize(boardSize, gapSize) {
    return {
        width: boardSize.width - gapSize.horizontal * 2,
        height: boardSize.height - gapSize.vertical * 2,
    };
}
exports.getDrawingAreaSize = getDrawingAreaSize;
function computeTransformParameters(transformParametersConfig) {
    var scaleRatio = computeScaleRatio(transformParametersConfig);
    var positionRange = computePositionRange(transformParametersConfig, scaleRatio);
    return { scaleRatio: scaleRatio, positionRange: positionRange };
}
exports.computeTransformParameters = computeTransformParameters;
function computePositionRange(transformParametersConfig, scaleRatio) {
    var scaledImageSize = util_1.computeScaledImageSize(transformParametersConfig.imageSize, scaleRatio);
    return {
        minX: getPositionRangeMinX(transformParametersConfig, scaledImageSize),
        maxX: getPositionRangeMaxX(transformParametersConfig, scaledImageSize),
        minY: getPositionRangeMinY(transformParametersConfig, scaledImageSize),
        maxY: getPositionRangeMaxY(transformParametersConfig, scaledImageSize),
    };
}
function getPositionRangeMinX(transformParametersConfig, scaledImageSize) {
    var gapSize = transformParametersConfig.gapSize, targetSize = transformParametersConfig.targetSize;
    return util_1.fixed((getDrawingAreaSize(targetSize, gapSize).width - scaledImageSize.width) / 2) + gapSize.horizontal;
}
function getPositionRangeMinY(transformParametersConfig, scaledImageSize) {
    var gapSize = transformParametersConfig.gapSize, targetSize = transformParametersConfig.targetSize;
    return util_1.fixed((getDrawingAreaSize(targetSize, gapSize).height - scaledImageSize.height) / 2) + gapSize.vertical;
}
function getPositionRangeMaxX(transformParametersConfig, scaledImageSize) {
    return util_1.fixed(getPositionRangeMinX(transformParametersConfig, scaledImageSize) + scaledImageSize.width);
}
function getPositionRangeMaxY(transformParametersConfig, scaledImageSize) {
    return util_1.fixed(getPositionRangeMinY(transformParametersConfig, scaledImageSize) + scaledImageSize.height);
}
exports.computeHelpers = {
    computeBoardRect: computeBoardRect,
    computeTransformParameters: computeTransformParameters,
    computeScaleRatio: computeScaleRatio,
    computeValidImageSize: computeValidImageSize,
    computePositionRange: computePositionRange,
    getPositionRangeMinX: getPositionRangeMinX,
    getPositionRangeMaxX: getPositionRangeMaxX,
    getPositionRangeMinY: getPositionRangeMinY,
    getPositionRangeMaxY: getPositionRangeMaxY,
};
