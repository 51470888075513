//输入 canvas-dom
//输出 压缩后的webp base64格式

import Compressor from "compressorjs";
import { removeBackgroundDownload } from "./remover-background/remove-background-download";
import { removeBackgroundEmoji } from "./remover-background/remove-background-emoji";
import { removeBackgroundDone } from "./remover-background/remove-background-done";
import { addDesignDone } from "./remover-background/add-design-done";
import { addDesignEmoji } from "./remover-background/add-design-emoji";
import { addDesignDownload } from "./remover-background/add-design-download";
import { fileUploadPre, uploadFile } from "../../api/image-filter";
var md5 = require("md5");

export function postCanvasToURL(dom, eventName, params) {
  // Convert canvas image to Base64
  var img = dom.toDataURL();
  // Convert Base64 image to binary
  var file = dataURItoBlob(img);
  compressorImage(file, eventName, params);
}

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);
  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

function compressorImage(file, eventName, params) {
  if (!file) {
    return;
  }
  console.log("[ file ] >", file);
  new Compressor(file, {
    quality: 0.4,
    maxWidth: 500,
    maxHeight: 500,
    convertSize: 5000000,
    mimeType: "image/webp",
    convertTypes: ["image/webp"],
    // The compression process is asynchronous,
    // which means you have to access the `result` in the `success` hook function.
    success(result) {
      console.log("[ result ] >", result);
      getBase64(result, eventName, params);
    },
    error(err) {
      console.log(err.message);
    },
  });
}

function getBase64(file, eventName, params) {
  uploadImage(file, eventName, params);
}
const uploadImage = (file, eventName, params) => {
  const timestamp = Date.now();
  const uploadsign = md5(".webp" + timestamp + "gJUyT9qGK");
  const uploadParams = {
    extension: ".webp",
    timestamp: timestamp,
    sign: uploadsign,
  };
  const formData = new FormData();
  fileUploadPre(uploadParams).then((res) => {
    const data = res.data;
    if (data.code == 0) {
      const uploadData = data.data;
      const { url, fields } = uploadData;

      formData.append("key", fields.key);
      formData.append("AWSAccessKeyId", fields.AWSAccessKeyId);
      formData.append("policy", fields.policy);
      formData.append("signature", fields.signature);

      formData.append("file", file);
      uploadFile(url, formData);
      //把返参中的key作为当前image-id
      const currentImageId = fields.key;
      //上传
      if (
        eventName == "toolkits_bgremove_download" ||
        eventName == "remove-cloth-background-download"
      ) {
        removeBackgroundDownload(eventName, currentImageId);
      } else if (
        eventName == "toolkits_bgremove_emoji" ||
        eventName == "remove-cloth-background-emoji"
      ) {
        removeBackgroundEmoji(eventName, params?.id, currentImageId);
      } else if (
        eventName == "toolkits_bgremove_edit" ||
        eventName == "remove-cloth-background-done"
      ) {
        removeBackgroundDone(eventName, currentImageId, params);
      } else if (
        eventName == "toolkits_bgremove_add_design_emoji" ||
        eventName == "add-cloth-design-emoji"
      ) {
        addDesignEmoji(eventName, params?.id, params?.imageId, currentImageId);
      } else if (
        eventName == "toolkits_bgremove_add_design_download" ||
        eventName == "add-cloth-design-download"
      ) {
        addDesignDownload(eventName, params, currentImageId);
      } else if (
        eventName == "toolkits_bgremove_add_design_edit" ||
        eventName == "add-cloth-design-done"
      ) {
        addDesignDone(eventName, params, currentImageId);
      }
    }
  });
};
