<template>
  <div class="matting-container">
    <a-modal v-model:visible="visible" :title="null" @ok="handleOk" @cancel="handleOk" :footer="null" width="840px"
      :maskClosable="false" centered>
      <template #closeIcon>
        <div class="close-icon">
          <img :src="close_icon" :style="{ width: '15.46px', height: '15.46px' }" />
        </div>

      </template>
      <div class="modal-wrap">
        <div class="modal-container">
          <div class="canvas-container">
            <div id="canvas-box" style="width: 454px; height: 454px;">
              <canvas :style="{ width: cvsWidth + 'px', height: cvsHeight + 'px' }" :width="cvsWidth * 2"
                :height="cvsHeight * 2" id="replace-bg-canvas" ref="imageCvsRef"></canvas>
            </div>

          </div>

          <div class="bgs-container">
            <p class="bg-title">Background</p>
            <div>
              <img v-for="item in bgsList" :key="item.id" :src="item.url" @click="handleSelectBg(item)"
                class="image-item" />
            </div>
          </div>
        </div>


        <div class="btns-box" :style="{ width: canvasWidth + 'px' }">
          <a-upload v-model:file-list="fileList" name="file" :multiple="false" :customRequest="customBgUpload"
            :showUploadList="false" @change="handleReplaceBgChange" :headers="headers"
            :beforeUpload="handleBeforeUpload" accept=".png,.jpg,.jpeg,.webp,.PNG,.JPG,.JPEG">
            <div type="default" class="upload-btn">Upload</div>
          </a-upload>
          <div type="default" class="upload-btn" @click="handleConfirm">Done</div>
          <!-- <a-button type="default" @click="handleConfirm">Done</a-button> -->
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import loadImage from "blueimp-load-image";
import { ref, defineProps, watchEffect, defineEmits, nextTick } from 'vue';
import { bgsList } from '../models/bgRemover/data.js'
import { addDesignPreview } from '@/utils/buriedFunction/remover-background/add-design-preview'
import { message } from "ant-design-vue";
import { renderCoverBgImageInCanvas } from '../matting-board/helpers/drawing-helper';
import { computeValidImageSize } from '../matting-board/composables/use-matting-board';
import close_icon from '@/assets/close_icon.svg'
import dataUtils from "@/utils/util.js";
const emits = defineEmits(['closeModal', 'confirmReplaceBg']);
const props = defineProps({
  isShowReplaceBgModal: Boolean,
  resultImageData: Object,
  defaultBgImages: Object,
  currentBgItem: Object
})
const visible = ref(false);
const canvasWidth = ref(454)
const canvasHeight = ref(454)

const imgMultiple = ref(1)
const uploadError = ref(false);
const bgFileList = ref([])
const imageCvsRef = ref(null)
const currentShowBgItem = ref(null)
const cvsWidth = ref(null)
const cvsHeight = ref(null)

watchEffect(async () => {
  visible.value = props.isShowReplaceBgModal

  if (props.resultImageData) {
    clearCanvas()
    await nextTick()
    setTimeout(() => {
      renderImageData()
    }, 0)
  }

  if (props.currentBgItem) {
    currentShowBgItem.value = props.currentBgItem
  }

})
const handleOk = () => {
  visible.value = false;
  emits('closeModal', false)
};

const handleBeforeUpload = (file) => {
  console.log(file)
  const acceptType = [
    "image/jpeg",
    "image/jpg",
    "image/webp",
    "image/png",
    "image/PNG",
    "image/JPEG",
    "image/JPG",
  ];
  const isJpgOrPng = acceptType.includes(file.type);
  message.config({
    top: "80%",
    duration: 3,
    icon: () => {
      return false;
    },
  });
  if (!isJpgOrPng) {
    message.error("Only JPG , PNG , JPEG and WEBP images are supported.");
    uploadError.value = true;
  }
};

const renderImageData = () => {
  const mattingElement = document.getElementById(
    "replace-bg-canvas"
  );
  const mattingCtx = mattingElement?.getContext("2d");
  if (!mattingElement || !mattingCtx) {
    return
  }
  if (props.currentBgItem) {
    if (props.currentBgItem.url) {
      var bgImage = new Image();//创建img元素
      bgImage.src = props.currentBgItem.url //背景图

      bgImage.onload = async function () {
        clearCanvas()
        const canvasBox = document.getElementById('canvas-box')

        var validImageSize = {}
        if (props.resultImageData.width < canvasBox.width && props.resultImageData.height < canvasBox.height) {
          validImageSize = {
            width: props.resultImageData.width,
            height: props.resultImageData.height,
          }
        } else {
          validImageSize = computeValidImageSize(
            props.resultImageData,
            { width: canvasBox.clientWidth, height: canvasBox.clientHeight },
            { horizontal: 0, vertical: 0 }
          );
        }


        cvsWidth.value = validImageSize.width;
        cvsHeight.value = validImageSize.height;
        if (bgImage) {
          await updateBgImage(bgImage);
          // await updateBgImage(props.bgData, bgValidImageSize);
        }
        updateSrcImage(props.resultImageData)

      }
    } else {
      drawBgImage(props.currentBgItem)
    }
  }
}


async function updateSrcImage (srcData) {
  const imageCvs = imageCvsRef.value;
  const imageCtx = imageCvs.getContext('2d');
  const srcBitmap = await createImageBitmap(srcData);
  imageCtx.drawImage(srcBitmap, 0, 0, imageCtx.canvas.width, imageCtx.canvas.height);
}

const showBackgroundImage = (originSize, resizeSize) => {
  canvasWidth.value = resizeSize.width
  canvasHeight.value = resizeSize.height
  const originWidth = originSize.width
  const originHeight = originSize.height
  const resizeSizeWidth = resizeSize.width
  const resizeSizeHeight = resizeSize.height

  if (originWidth > resizeSizeWidth || originHeight > resizeSizeHeight) {
    // 长 > 宽
    if (resizeSizeWidth / originWidth > resizeSizeHeight / originHeight) {
      if (resizeSizeWidth / originWidth < 1 && resizeSizeHeight / originHeight < 1) {
        imgMultiple.value = canvasHeight.value / originHeight
      } else if (resizeSizeWidth / originWidth >= 1 && resizeSizeHeight / originHeight < 1) {
        imgMultiple.value = resizeSizeHeight / originHeight
      }

    } else if (resizeSizeWidth / originWidth < resizeSizeHeight / originHeight) {
      // 宽 > 长
      if (resizeSizeWidth / originWidth <= 1 && resizeSizeHeight / originHeight < 1) {

        imgMultiple.value = resizeSizeWidth / originWidth

      } else if (resizeSizeWidth / originWidth < 1 && resizeSizeHeight / originHeight > 1) {

        imgMultiple.value = resizeSizeWidth / originWidth
      }

    } else {
      imgMultiple.value = resizeSizeWidth / originWidth
    }
  } else if (originWidth <= resizeSizeWidth && originHeight <= resizeSizeHeight) {

    // 长 > 宽
    if (resizeSizeWidth / originWidth > resizeSizeHeight / originHeight) {
      imgMultiple.value = resizeSizeHeight / originHeight
    } else if (resizeSizeWidth / originWidth < resizeSizeHeight / originHeight) {
      imgMultiple.value = resizeSizeWidth / originWidth
    } else {
      imgMultiple.value = 1
    }
  }
  const imgWidth = imgMultiple.value * originWidth
  const imgHeight = imgMultiple.value * originHeight


  const imgX = (454 - imgWidth) / 2
  const imgY = (454 - imgHeight) / 2


  return {
    imgX,
    imgY,
    imgWidth,
    imgHeight
  }
}

async function updateBgImage (bgData) {
  const imageCvs = imageCvsRef.value;
  const imageCtx = imageCvs.getContext('2d');
  const bgBitmap = await createImageBitmap(bgData);
  renderCoverBgImageInCanvas(bgBitmap, imageCtx);
}


const handleSelectBg = async (item) => {

  if (currentShowBgItem.value.id == item.id) return

  const canvasBox = document.getElementById('canvas-box')
  var validImageSize = {}
  if (props.resultImageData.width < canvasBox.width && props.resultImageData.height < canvasBox.height) {
    validImageSize = {
      width: props.resultImageData.width,
      height: props.resultImageData.height,
    }
  } else {
    validImageSize = computeValidImageSize(
      props.resultImageData,
      { width: canvasBox.clientWidth, height: canvasBox.clientHeight },
      { horizontal: 0, vertical: 0 }
    );
  }

  cvsWidth.value = validImageSize.width;
  cvsHeight.value = validImageSize.height;
  // 颜色填充

  if (item.type === 'color' && item.color) {
    const canvasCtx = dataUtils.createCanvasCtx({ width: props.resultImageData.width, height: props.resultImageData.height })
    canvasCtx.rect(0, 0, props.resultImageData.width, props.resultImageData.height);
    // 登录状态下不会出现这行文字，点击页面右上角一键登录
    canvasCtx.fillStyle = item.color
    canvasCtx.fillRect(0, 0, props.resultImageData.width, props.resultImageData.height);
    const url = canvasCtx.canvas.toDataURL()
    item.url = url
  }

  var bgImage = new Image();//创建img元素
  bgImage.src = item.url //背景图
  currentShowBgItem.value = item
  //上传 用户预览背景
  addDesignPreview("toolkits_bgremove_add_design_preview", item.id)
  bgImage.onload = async function () {
    clearCanvas()
    if (bgImage) {
      await updateBgImage(bgImage);
      // await updateBgImage(props.bgData, bgValidImageSize);
    }
    // 组合两张图
    updateSrcImage(props.resultImageData)
  }



}

// 清除canvas画布
const clearCanvas = () => {
  const theCanvas = document.getElementById('replace-bg-canvas')

  if (!theCanvas) {
    return
  }
  const context = theCanvas.getContext("2d");
  var w = theCanvas.width;
  var h = theCanvas.height;
  context.clearRect(0, 0, w, h);
}


const customBgUpload = () => {
  console.log('上传背景')
}

const handleReplaceBgChange = (value) => {
  if (uploadError.value) {
    return;
  }
  bgFileList.value = value.fileList;
  currentShowBgItem.value = value.file.originFileObj;
  const file = value.file.originFileObj;
  // uploadBackground(file);
  drawBgImage(file)
}


const drawBgImage = (url) => {
  if (!url) {
    return;
  }
  loadImage(
    url,
    async (bgImage) => {
      if ((bgImage).type === "error") {
        return
      }

      const canvasBox = document.getElementById('canvas-box')
      var validImageSize = {}
      if (props.resultImageData.width < canvasBox.width && props.resultImageData.height < canvasBox.height) {
        validImageSize = {
          width: props.resultImageData.width,
          height: props.resultImageData.height,
        }
      } else {
        validImageSize = computeValidImageSize(
          props.resultImageData,
          { width: canvasBox.clientWidth, height: canvasBox.clientHeight },
          { horizontal: 0, vertical: 0 }
        );
      }
      cvsWidth.value = validImageSize.width;
      cvsHeight.value = validImageSize.height;
      if (bgImage) {
        await updateBgImage(bgImage);
      }
      updateSrcImage(props.resultImageData)
    },
    {
      cover: true,
      crop: true,
      canvas: true,
      crossOrigin: "Anonymous",
    }
  );

}

const handleConfirm = () => {
  handleOk();
  emits('confirmReplaceBg', currentShowBgItem.value);
}

</script>

<style lang="less">
.ant-modal-body {
  padding: 30px 36px 24px 36px;
  // background-color: green;

}

.close-icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all .5s;

  &:hover {
    background-color: #eaeaea;
  }
}

.modal-container {
  height: 100%;
  width: 100%;
  display: flex;

}

.canvas-container {
  // height: 100%;
  flex: 1;
  // display: flex;
  // flex-direction: column;
}

.btns-box {
  margin-top: 14px;
  display: flex;
  justify-content: center;

  .ant-btn {
    width: 130px;
    height: 42px;
    border: 1px solid #D0D0D0;
    border-radius: 200px;
    cursor: pointer;
  }

  .upload-btn {
    margin-right: 16px;
    width: 130px;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  button[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    color: rgba(0, 0, 0, 0.85);
    animation: none 0 ease 0 1 normal;
  }

  .upload-btn:hover {
    color: rgba(0, 0, 0, 0.85);
    background-color: #EDEDED !important;
    outline: none;
  }

  .upload-btn:focus {
    outline: none
  }
}

.bgs-container {
  height: 100%;
  width: 270px;
  min-width: 270px;
  margin-left: 36px;

  p {
    margin-bottom: 10px;
  }

  img {
    border-radius: 4.14545px;
    margin-right: 12px;
    margin-bottom: 8.93px;
  }
}




#canvas-box {
  width: 454px;
  height: 454px;
  background-color: #EDEDED;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-title {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.image-item {
  width: 76px;
  height: 76px;
  cursor: pointer;
}
</style>