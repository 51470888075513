import Compressor from "compressorjs";
var md5 = require("md5");
import { fileUploadPre, uploadFile } from "../../api/image-filter";

export function uploadBackground(file) {
  if (!file) {
    return;
  }

  new Compressor(file, {
    quality: 0.5,
    maxWidth: 500,
    maxHeight: 500,
    convertSize: 5000000,
    mimeType: "image/webp",
    convertTypes: ["image/webp"],
    // The compression process is asynchronous,
    // which means you have to access the `result` in the `success` hook function.
    success(result) {
      uploadImage(result);
    },
    error(err) {
      console.log(err.message);
    },
  });
}

const uploadImage = (result) => {
  const timestamp = Date.now();
  const deviceId = localStorage.getItem("browserId");
  const sessionId = sessionStorage.getItem("sessionId");
  const eventName = "upload_image";
  const signId = md5(1 + deviceId + timestamp + eventName + "gJUyT9qGK");
  //图片id
  const imageId = md5(timestamp);

  //width height max=500px
  //max size 256kb
  //format webp

  const uploadsign = md5(".webp" + timestamp + "gJUyT9qGK");
  const uploadParams = {
    extension: ".webp",
    timestamp: timestamp,
    sign: uploadsign,
  };
  const formData = new FormData();

  fileUploadPre(uploadParams).then((res) => {
    const data = res.data;
    if (data.code == 0) {
      console.log("[ data ] >", data);
      const uploadData = data.data;
      const { url, fields } = uploadData;

      //把返参中的key作为image-id
      formData.append("key", fields.key);
      formData.append("AWSAccessKeyId", fields.AWSAccessKeyId);
      formData.append("policy", fields.policy);
      formData.append("signature", fields.signature);

      formData.append("file", result);
      uploadFile(url, formData);
    }
  });
};

function getBase64(file) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    uploadImage(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}
