<template>
  <div class="rate-container">
    <div class="rate-icons-box" v-if="showIcons" @mouseenter="handleRateEnter()" @mouseleave="handleRateLeave()">
      <div class="rate-icon-tip-position">
        <div class="rate-icons-layout">
          <div v-for="item in rateIcons" :key="item.id" class="icon-box">
            <div class="icon-tip-box" :style="{ left: item.left + 'px' }"
              :class="{ 'show-tip': currentRate.id === item.id }">
              <div class="icon-tip">
                {{ item.tip }}
              </div>
              <img :src="iconArrow" class="icon-arrow" />
            </div>
            <img :src="item.bigIconPath" class="normal-style" :class="{ 'active-style': currentRate.id === item.id }"
              @mouseenter="handleRateIconEnter(item)" @mouseleave="handleRateIconLeave(item)"
              @click="handleSelectRateIcon(item)" />
          </div>
        </div>
      </div>
    </div>
    <div class="rate-btn-container">
      <div class="hover-box" @mouseleave="handleRateLeave()" @mouseenter="handleRateEnter()">
        <div v-if="selectedRate && selectedRate.id" class="icon-selected-box" @click="handleCancelSelect">
          <img :src="selectedRate.iconPath" class="icon-selected" />
        </div>
        <img :src="rateIcon" class="rate-icon" v-else />
      </div>
      <h3 class="rate-tip">Rate this result</h3>
    </div>
  </div>
</template>
<script setup>
import rateIcon from "@/assets/rate.svg";
import iconSurprise from "@/assets/rate-icons/icon-surprise.svg";
import iconBigSurprise from "@/assets/rate-icons/icon-big-surprise.svg";
import iconBigNausea from "@/assets/rate-icons/icon-big-nausea.svg";
import iconNausea from "@/assets/rate-icons/icon-nausea.svg";
import iconBigDoubt from "@/assets/rate-icons/icon-big-doubt.svg";
import iconDoubt from "@/assets/rate-icons/icon-doubt.svg";
import iconBigLove from "@/assets/rate-icons/icon-big-love.svg";
import iconLove from "@/assets/rate-icons/icon-love.svg";
import iconArrow from "@/assets/rate-icons/icon_arrow.svg";
import { ref, defineEmits, onMounted, defineProps, watchEffect } from "vue";
const props = defineProps({
  clearRate: Boolean
})
const emits = defineEmits(["changeRate"]);

const rateIcons = [
  {
    id: 1,
    iconPath: iconSurprise,
    bigIconPath: iconBigSurprise,
    tip: 'Amazing',
    left: -3
  },
  {
    id: 2,
    iconPath: iconLove,
    bigIconPath: iconBigLove,
    tip: 'Good',
    left: 65
  },

  {
    id: 3,
    iconPath: iconDoubt,
    bigIconPath: iconBigDoubt,
    tip: 'Doubt',
    left: 125
  },

  {
    id: 4,
    iconPath: iconNausea,
    bigIconPath: iconBigNausea,
    tip: 'Spit',
    left: 190
  },
];

const currentRate = ref({});
const selectedRate = ref({});
const showIcons = ref(false);

watchEffect(() => {
  if (props.clearRate == true) {
    currentRate.value = {}
    selectedRate.value = {}
  }
})

const handleClearRate = () => {
  currentRate.value = {}
  selectedRate.value = {}
}

defineExpose({
  handleClearRate,
})

const handleRateEnter = () => {
  showIcons.value = true;
};

const handleRateLeave = () => {
  showIcons.value = false;
};
const handleRateIconEnter = (item) => {
  currentRate.value = item;
};

const handleRateIconLeave = () => {
  currentRate.value = {};
};
const handleSelectRateIcon = (item) => {
  //数据上报
  selectedRate.value = item;
  emits("changeRate", item);
};

const handleCancelSelect = () => {
  // currentRate.value = {}
  // selectedRate.value = {}
}
</script>

<style lang="less">
.rate-container {
  position: relative;
  z-index: 999;
}

.rate-btn-container {
  .rate-icon {
    cursor: pointer;
  }

  .ant-btn {
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    height: 42px;
    width: 130px;
  }

  .rate-tip {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #969696;
    margin-top: 8px;
  }
}

.rate-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .hover-box {
    padding-top: 12px;
    width: 60px;
    height: 50px;
    display: flex;
    justify-content: center;
  }
}

.rate-icons-box {
  width: 245px;
  height: 54px;
  position: absolute;
  top: -50px;
  left: 50%;
  margin-left: -122.5px;
}


.rate-icons-layout {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  background: rgba(37, 37, 37, 0.9);
  border-radius: 600px;
  // animation: icons-animation 0.1s 1;
}

@keyframes icons-animation {
  0% {
    opacity: 0.5;
    align-items: flex-end;
  }

  100% {
    opacity: 1;
    align-items: center;
  }
}

.normal-style {
  width: 42px;
  height: 42px;
  cursor: pointer;
}

.active-style {
  transform: scale(1.62);
  transition: 0.05s;
}

.emoji-tip {
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.overlayStyle {
  border-radius: 4px;
  margin-bottom: 20px;

  .ant-tooltip-inner {
    border-radius: 4px;
  }
}

.icon-selected {
  width: 24px;
  height: 24px;
}

.icon-selected-box {
  width: 36px;
  height: 36px;
  border: 1px solid #D0D0D0;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.rate-icon-tip-position {
  position: relative;
  width: 100%;
  height: 100%;
}

.icon-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon-tip-box {
  position: absolute;
  top: -51px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0 12px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  opacity: 0;

  .icon-arrow {
    position: absolute;
    bottom: -16px;
    // left: 36px;
  }
}

.show-tip {
  opacity: 1;
  animation: show-tip-animation 0.05s 1;
}


@keyframes show-tip-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.icon-tip {
  color: #FFFFFF;
}
</style>