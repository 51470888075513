"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformHelpers = exports.redrawMattingBoardsWhileScaling = exports.updateRangeByMovements = exports.generateRangeOffset = void 0;
var constants_1 = require("./constants");
var init_compute_1 = require("./init-compute");
var sign = Math.sign;
function generateRangeOffset(rangeOffsetConfig) {
    var pageX = rangeOffsetConfig.pageX, pageY = rangeOffsetConfig.pageY, positionRange = rangeOffsetConfig.positionRange;
    var minX = positionRange.minX, maxX = positionRange.maxX, minY = positionRange.minY, maxY = positionRange.maxY;
    return { minX: minX - pageX, maxX: maxX - pageX, minY: minY - pageY, maxY: maxY - pageY };
}
exports.generateRangeOffset = generateRangeOffset;
function updateRangeByMovements(ev, positionRange) {
    var deltaX = ev.movementX, deltaY = ev.movementY;
    positionRange.minX += deltaX;
    positionRange.maxX += deltaX;
    positionRange.minY += deltaY;
    positionRange.maxY += deltaY;
}
exports.updateRangeByMovements = updateRangeByMovements;
function redrawMattingBoardsWhileScaling(ev, scalingConfig) {
    var transformConfig = scalingConfig.transformConfig;
    updateTransformConfigWhileScaling(ev, transformConfig);
}
exports.redrawMattingBoardsWhileScaling = redrawMattingBoardsWhileScaling;
function updateTransformConfigWhileScaling(ev, transformConfig) {
    var deltaY = ev.deltaY, pageX = ev.pageX, pageY = ev.pageY, target = ev.target;
    var positionRange = transformConfig.positionRange, scaleRatio = transformConfig.scaleRatio;
    var _a = init_compute_1.computeHelpers.computeBoardRect(target), left = _a.left, top = _a.top;
    var x = exports.transformHelpers.computePivot(pageX, left);
    var y = exports.transformHelpers.computePivot(pageY, top);
    var deltaRatio = exports.transformHelpers.computeDeltaRatio(deltaY);
    var targetScaleRatio = exports.transformHelpers.computeNewScaleRatio(scaleRatio, deltaRatio);
    transformConfig.scaleRatio = exports.transformHelpers.computeClampedTargetScaleRatio(targetScaleRatio);
    var rangeScaleRatio = computeRangeScaleRatio(transformConfig.scaleRatio, scaleRatio);
    transformConfig.positionRange = exports.transformHelpers.computeNewPositionRange(positionRange, { x: x, y: y }, rangeScaleRatio);
}
function computePivot(pagePivot, leftOrTop) {
    return pagePivot - leftOrTop;
}
function computeDeltaRatio(deltaY) {
    var scaleCoefficient = exports.transformHelpers.isZoomOut(deltaY) ? constants_1.ZOOM_OUT_COEFFICIENT : constants_1.ZOOM_IN_COEFFICIENT;
    return scaleCoefficient * constants_1.SCALE_STEP;
}
function isZoomOut(deltaY) {
    return -sign(deltaY) === constants_1.ZOOM_OUT_COEFFICIENT;
}
function computeNewScaleRatio(scaleRatio, deltaRatio) {
    return scaleRatio + scaleRatio * deltaRatio;
}
function computeRangeScaleRatio(newRatio, oldRatio) {
    return (newRatio - oldRatio) / oldRatio;
}
function computeClampedTargetScaleRatio(scaleRatio) {
    return scaleRatio < constants_1.MIN_SCALE_RATIO ? constants_1.MIN_SCALE_RATIO : scaleRatio > constants_1.MAX_SCALE_RATIO ? constants_1.MAX_SCALE_RATIO : scaleRatio;
}
function computeNewPositionRange(positionRange, position, deltaRatio) {
    var x = position.x, y = position.y;
    var minX = positionRange.minX, maxX = positionRange.maxX, minY = positionRange.minY, maxY = positionRange.maxY;
    minX = exports.transformHelpers.computeNewSingleRange(minX, x, deltaRatio);
    maxX = exports.transformHelpers.computeNewSingleRange(maxX, x, deltaRatio);
    minY = exports.transformHelpers.computeNewSingleRange(minY, y, deltaRatio);
    maxY = exports.transformHelpers.computeNewSingleRange(maxY, y, deltaRatio);
    return { minX: minX, maxX: maxX, minY: minY, maxY: maxY };
}
function computeNewSingleRange(singleRange, pivot, deltaRatio) {
    var vectorDistance = singleRange - pivot;
    var deltaRange = vectorDistance * deltaRatio;
    return singleRange + deltaRange;
}
exports.transformHelpers = {
    updateTransformConfigWhileScaling: updateTransformConfigWhileScaling,
    computePivot: computePivot,
    computeDeltaRatio: computeDeltaRatio,
    isZoomOut: isZoomOut,
    computeNewScaleRatio: computeNewScaleRatio,
    computeClampedTargetScaleRatio: computeClampedTargetScaleRatio,
    computeNewPositionRange: computeNewPositionRange,
    computeNewSingleRange: computeNewSingleRange,
};
