

import bgGry from "@/assets/bg-images/gray_icon.png";
import bgBlack from "@/assets/bg-images/black_icon.png";
import bgWhite from "@/assets/bg-images/white_bg.png";

export const bgsList = [
  {
    id: 1,
    url: bgWhite,
    type: 'color',
    color: '#fff'
  },
  {
    id: 2,
    url: bgGry,
    type: 'color',
    color: '#eee'
  },
  {
    id: 3,
    url: bgBlack,
    type: 'color',
    color: '#000'
  },
  {
    id: 4,
    url: "/ai_tools/backgrounds/3VuFEc65rCemIaqll97ISd.webp",
  },
  {
    id: 5,
    url: "/ai_tools/backgrounds/5DpjreDULpthMF0Dd5aRq6.webp",
  },
  {
    id: 6,
    url: "/ai_tools/backgrounds/5uPVGaOp0ehIggt8w2z7Fe.webp",
  },
  {
    id: 7,
    url: "/ai_tools/backgrounds/8SJLlBnTk7EIbKjUJLcvIq.webp",
  },
  {
    id: 8,
    url: "ai_tools/backgrounds/do2q5JeLnAJ4vz3IIMoNmb.webp",
  },
  {
    id: 9,
    url: "/ai_tools/backgrounds/f7DmFP2Ml92yGGGDFGdPOi.webp",
  },
  {
    id: 10,
    url: "/ai_tools/backgrounds/ok8PI8mcafAb94QiMIseSf.webp",
  },
  {
    id: 11,
    url: "/ai_tools/backgrounds/qlhTqCcV3F5wh2AFop2iD0.webp",
  },
  {
    id: 12,
    url: "/ai_tools/backgrounds/rP4OoIow2Jrpu45DdMSkqU.webp",
  },
  {
    id: 13,
    url: "/ai_tools/backgrounds/tikBm2FEjb3sVABtHheRTb.webp",
  },
];
